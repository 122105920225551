import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * Esta es la forma extra abreviada de declarar un componente.
 * No lleva ni nombre de componente.
 * Fijate que no usa {} por que no hay variables en el componente.
 * Fijate que no usa return.
 * Mira comentarios en archivo layout
 *
 */
export default () => {

	const data = useStaticQuery(
    graphql`
      query Logo {
        logotype: file(relativePath: {eq: "icon.png"}) {
          childImageSharp {
            fixed(width: 33, height: 33) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

	const imageFixed=data.logotype.childImageSharp.fixed

	return(
		<Img fixed={imageFixed} />
	)

}
