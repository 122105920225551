import React from "react"
import { Grid, Styled } from "theme-ui"

const Layout = ({children}) => {

  return (
    <Styled.root>
      <Grid
        as={`div`}
        gap={0}
        columns={[`1fr`,`1fr`, `1fr`, `15rem 1fr`, `16rem 1fr`]}
        sx={{
          gridTemplateAreas:[`"header" "main"`, `"header" "main"`, `"header" "main"`, `". main"`, `". main"`],
          header:{
            gridArea: `header`,
            height: [`auto`, `auto`, `auto`, `100vh`, `100vh`],
            maxHeight: [`none`, `none`, `none`, `100vh`, `100vh`],
            width: [`100vw`, `100vw`, `100vw`, `15rem`, `16rem`],
            position: [`inherit`, `inherit`, `inherit`, `fixed`, `fixed`],
          },
          main:{
            gridArea: `main`,
          },
        }}
      >
        {children}
      </Grid>
    </Styled.root>
  )

}

export default Layout
